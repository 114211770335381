; (function () {
    'use strict';

    var datePicker = $(".date input, .input-daterange input");
    datePicker.parent().css("position", "relative");

    function createDisplayStr(elem) {
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];        
        var str = "";
        var val = elem.val().split("/");

        if (elem.attr("placeholder").toLowerCase() == "dd/mm/yyyy") {
            str = val[0] + " " + months[val[1] - 1] + " " + val[2];
        } else if (elem.attr("placeholder").toLowerCase() == "mm/yyyy") {
            str = months[val[0] - 1] + " " + val[1];
        } else {
            str = val[1] + " " + months[val[0] - 1] + " " + val[2];
        }

        return str;
    }

    //datePicker.on("change", function () {
    //    if ($(this).parent().next().hasClass("error")) {
    //        $(this).parent().next().remove();
    //    }

    //    if ($(this).val() != "") {
    //        var str = createDisplayStr($(this));
    //        var val = $(this).val().split("/");

    //        if (val.length > 3 || val.length == 3 && str.indexOf("undefined") >= 0) {
    //            str = "";
    //            $(this).val("");
    //            $(this).parent().after("<span class='help-block error'>Please enter a valid date.</span>");
    //        } else if (val.length == 3) {
    //            $(this).parent().attr("data-before", str);

    //            $(".datepicker.datepicker-dropdown.dropdown-menu").remove();
    //        }            
    //    } else {
    //        $(this).parent().removeAttr("data-before");
    //        $(".datepicker.datepicker-dropdown.dropdown-menu").remove();
    //    }
    //});

    $.each(datePicker, function () {
        if ($(this).is(":disabled")) $(this).parent().addClass("disabled");
        if ($(this).val() != "") $(this).trigger("change");
    });

}());